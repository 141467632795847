import { policyList } from "@/enums/permissions";
import {
  Timeclock,
  timeclockDefault,
  TimeclockEvent
} from "@/interfaces/timeclock";
import { User } from "@/interfaces/user";
import { EventBus } from "@/internal";
import { timeclockService } from "@/services/timeclock.service";
import { Callback, CallbackPromise, PageNavAction } from "@/types/types";
import { FNS_DATE_FORMATS, fnsFormatDate } from "@/utils/date-fns.utils";
import addMinutes from "date-fns/addMinutes";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import differenceInMinutes from "date-fns/differenceInMinutes";
import isAfter from "date-fns/isAfter";
import {
  ActionsSubheaderComponent,
  BooleanCheck,
  PageTitleComponent,
  TableAction,
  TableComponent,
  TableFieldType,
  TableHeader,
  TableSuccessModalResponse
} from "helix-vue-components";
import chunk from "lodash/chunk";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import { Validator } from "vee-validate";
import { Component, Inject, Provide, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { TimeEventType } from "../Timeclock.enum";
import { TimeclockEvents } from "../Timeclock.events";
import TimeclockEventModalComponent from "./timeclockEvent/TimeclockEvent.component";
import Template from "./TimeclockForm.template.vue";

const namespace: string = "TimeclockModule";

@Component({
  components: {
    TableComponent,
    TimeclockEventModalComponent,
    ActionsSubheaderComponent,
    PageTitleComponent
  },
  mixins: [Template]
})
export default class TimeclockFormComponent extends Vue {
  public model: Timeclock = cloneDeep(timeclockDefault);
  public i: number = 0;
  public unpairedBreaksMessage: string = "";
  public dateMenu = false;

  @Getter("users", { namespace: "UserModule" })
  public storeUsersItems!: User[];
  @Getter("loading", { namespace: "UserModule" })
  public isLoading!: boolean;
  @Action("searchUsers", { namespace: "UserModule" })
  public searchUserAction!: Callback;

  public get userItems() {
    return !!this.model.user
      ? [this.model.user, ...this.storeUsersItems]
      : this.storeUsersItems;
  }

  @Getter("currentTimeclock", { namespace })
  public current!: Timeclock | null;
  @Action("findTimeclock", { namespace })
  public findTimeclock!: CallbackPromise<any>;
  @Action("deleteEvent", { namespace })
  public deleteEventAction!: CallbackPromise<any>;
  @Action("saveTimeclock", { namespace })
  public saveTimeclock!: Callback;
  @Action("deleteTimeclock", { namespace })
  public deleteTimeclock!: Callback;
  @Getter("loading", { namespace })
  public loadingTimeclock!: boolean;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  @Inject("$validator") public $validator!: Validator;
  @Provide()
  public validator = this.$validator;
  public canCreate = false;
  public canDelete = false;
  public managerPin: string = "";
  public eventForEdition: TimeclockEvent | null = null;
  public delayTimer: number | undefined = undefined;
  public searchUserField: string = "";
  public deletedEvents: TimeclockEvent[] = [];
  public debounceUserSearch = debounce(async (userName: string) => {
    if (!userName || userName.length > 2) {
      await this.searchUserAction(userName);
    }
  }, 500);

  public headers: TableHeader[] = [];
  public rowActions: TableAction[] = [];
  public generalActions: TableAction[] = [];

  public onNewEvent(event: {
    created_at: string;
    id: number | null;
    temporalId?: number | null | undefined;
    event: string;
  }): void {
    this.timeclockEventsParsed.push({
      ...event,
      registered_at: fnsFormatDate(
        new Date(Date.parse(event.created_at as string)),
        FNS_DATE_FORMATS.EN_BARS_WITH_MERIDIEM_TIME
      )
    });
  }

  public setRowActions() {
    return [
      {
        icon: "fal fa-pen",
        action: (eventForEdition: any) => {
          this.modalToggle(eventForEdition);
        },
        visibleCondition: (item: any, index: number) => {
          const previous = this.model.timeclock_events[index - 1];
          const next = this.model.timeclock_events[index + 1];
          return this.hasPermission(policyList.modifyTimeClockEvents) &&
            previous &&
            next
            ? isAfter(
                new Date(next.registered_at),
                addMinutes(new Date(previous.registered_at), 2)
              )
            : true;
        }
      },
      {
        icon: "fal fa-trash-alt",
        visibleCondition: (item: any, index: number) => {
          return (
            this.canDelete &&
            item.event !== TimeEventType.CHECK_IN &&
            item.event !== TimeEventType.CHECK_OUT
          );
        },
        modalActions: {
          modalNumber: 1,
          modalQuestion: this.$t("event_category_delete_confirm").toString(),
          modalSuccessText: "yes",
          modalSuccessAction: (arg: TableSuccessModalResponse) => {
            if (arg.item.event !== "CHECK_IN") {
              this.deleteEvent(arg.item as TimeclockEvent, arg.position);
            }
            arg.unselectModal();
          },
          modalCancelText: "no"
        }
      }
    ];
  }

  public addEventModal() {
    if (!this.model.user) {
      EventBus.$emit("notify", {
        text: "timeclock.set_user",
        color: "warning"
      });
      return;
    }
    const rangeValid =
      !this.model.timeclock_events.length ||
      this.model.timeclock_events.length % 2 ||
      chunk(this.model.timeclock_events, 2)
        .map(e => {
          const top = differenceInMinutes(
            new Date(e[1].registered_at),
            new Date(e[0].registered_at)
          );

          return (top >= 10 && top) || 0;
        })
        .reduce((acc, n) => {
          return acc + n;
        });
    if (rangeValid) {
      this.modalToggle();
    } else {
      EventBus.$emit("notify", {
        text: "timeclock.cannot_add_events_due_range",
        color: "warning"
      });
    }
  }

  public get timeclockEventsParsed() {
    return this.model.timeclock_events.map(time => ({
      ...time,
      registered_at: fnsFormatDate(
        new Date(time.registered_at),
        FNS_DATE_FORMATS.EN_BARS_WITH_MERIDIEM_TIME
      )
    }));
  }

  public addNewEvent(newValue: TimeclockEvent) {
    this.model.timeclock_events!.push({
      ...newValue,
      temporalId: this.i + 1
    });
    this.i++;
    this.eventForEdition = null;
    this.model.timeclock_events.sort((a: TimeclockEvent, b: TimeclockEvent) => {
      return differenceInMilliseconds(
        new Date(a.registered_at),
        new Date(b.registered_at)
      );
    });
  }

  public editEvent(newValue: TimeclockEvent) {
    const copy = this.model.timeclock_events.map((element: TimeclockEvent) => {
      if (
        (element.id && newValue.id && isEqual(element.id, newValue.id)) ||
        (element.temporalId &&
          newValue.temporalId &&
          isEqual(element.temporalId, newValue.temporalId))
      ) {
        return { ...newValue };
      } else {
        return { ...element };
      }
    });
    this.model.timeclock_events = copy;
  }

  public deleteEvent(eventToDelete: TimeclockEvent, index: number) {
    if (eventToDelete.id) {
      this.deletedEvents.push(eventToDelete);
    }

    this.model.timeclock_events!.splice(index, 1);

    EventBus.$emit("closeTableModal");
  }

  get pairedBreaks() {
    const breaks = this.model.timeclock_events.filter(element =>
      ["BREAK_TIME_IN", "BREAK_TIME_OUT"].includes(element.event)
    );
    const pairedBreaks = breaks.length % 2 === 0;
    return pairedBreaks;
  }

  // button callbacks
  public cancel() {
    this.$router.push({ name: "time-clock-manager" });
  }

  public async saveTimeclockItem() {
    const validator = await this.$validator.validateAll();
    if (!this.pairedBreaks) {
      EventBus.$emit("notify", {
        text: "need_to_add_breaktime_out",
        color: "warning"
      });
      return;
    }
    this.unpairedBreaksMessage = "";
    if (validator) {
      // if it has an id, its an edition
      if (this.model.id) {
        // clean timeclock events format
        this.model.timeclock_events.forEach(element => {
          delete element.temporalId;
          if (!element.id) {
            delete element.id;
          }
        });
        try {
          if (this.deletedEvents.length) {
            await Promise.all(
              this.deletedEvents.map(event =>
                this.deleteEventAction({
                  timeclockId: this.model.id,
                  eventId: event.id
                })
              )
            );
          }
          await timeclockService.updateTimeclock(this.model.id, this.model);
          this.$router.push({
            name: "time-clock-manager"
          });
        } catch (e) {
          EventBus.$emit("notify", {
            text: "timeclock.couldnt_save_timeclock",
            color: "warning"
          });
        }
      }
    }
  }

  // item edition or add
  public modalToggle(eventForEdition?: TimeclockEvent) {
    this.$modals
      .load<{ data: TimeclockEvent; type: string }>(
        TimeclockEventModalComponent,
        {
          size: "fit",
          positionY: "top"
        },
        {
          onCreate: this.onNewEvent,
          event: eventForEdition,
          allEvents: this.model.timeclock_events,
          model: this.model
        }
      )
      .then((data: { type: string; data: TimeclockEvent }) => {
        if (data.type === "new") {
          this.addNewEvent(data.data);
        } else {
          this.editEvent(data.data);
        }
      })
      .catch(() => {
        // No action on close.
      });
  }

  public async deleteTimeclockItem() {
    this.deleteTimeclock(this.model);
  }

  // employee search when modified
  @Watch("searchUserField")
  public async searchData(userName: any) {
    this.debounceUserSearch(userName);
  }

  get formTitle() {
    const name =
      (this.model &&
        this.model.id &&
        this.model.user &&
        `- ${this.model.user.first_name} ${this.model.user.last_name}`) ||
      "";
    const title = this.$t("timeclock.timeclock").toString();
    return `${title.toUpperCase()} ${name.toUpperCase()}`;
  }

  @Watch("current")
  public updateTable() {
    this.model = this.current!;
    this.model.timeclock_events = this.model.timeclock_events.sort(
      (a: TimeclockEvent, b: TimeclockEvent) => {
        return differenceInMilliseconds(
          new Date(a.registered_at),
          new Date(b.registered_at)
        );
      }
    );
  }

  private setHeaders() {
    return [
      {
        value: "event",
        label: this.$t("event").toString(),
        fieldComponent: TableFieldType.enumText,
        class: "tdt__headers__fieldLong",
        sortable: false
      },
      {
        value: "registered_at",
        label: this.$t("time").toString(),
        fieldComponent: TableFieldType.string,
        class: "tdt__headers__fieldLong",
        sortable: false
      }
    ];
  }

  private async mounted() {
    this.setPageNav({
      title: this.formTitle,
      isLoading: () => this.loadingTimeclock,
      elevation: 0,
      rightActions: {
        generalActions: () => [
          { icon: "fal fa-check", action: this.saveTimeclockItem },
          { icon: "fal fa-times", action: this.cancel }
        ]
      },
      leftActions: {
        component: ActionsSubheaderComponent,
        props: {
          generalActions: [
            {
              icon: "fal fa-trash",
              action: this.deleteTimeclockItem,
              visibleCondition: () => this.model.id && this.canDelete
            }
          ]
        }
      }
    });
    this.canCreate = this.hasPermission(policyList.createTimeClockEvents);
    this.canDelete = this.hasPermission(policyList.deleteTimeClockEvents);
    this.headers = this.setHeaders();
    this.rowActions = this.setRowActions();
    this.generalActions = [
      {
        icon: "fal fa-plus",
        visibleCondition: () => this.canCreate,
        action: (...arg: any[]) => {
          this.addEventModal();
        }
      }
    ];
    await this.loadTimeclockData();
    EventBus.$on(`tc${TimeclockEvents.CREATED}`, this.loadTimeclockData);
  }

  private async loadTimeclockData() {
    if (this.$route.params.id) {
      await this.findTimeclock(this.$route.params.id);
      this.model = this.current || this.model;
    }

    this.model.timeclock_events.sort((a: TimeclockEvent, b: TimeclockEvent) => {
      return differenceInMilliseconds(
        new Date(a.registered_at),
        new Date(b.registered_at)
      );
    });
  }
}
